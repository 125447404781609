<template>
<div class="card shadow-sm">
    <div class="card-header">
        <h5 class="card-title">Parent or Guardian's details</h5>
    </div>
    <div class="card-body">
        <div class="row g-3 g-md-4">
            <div class="col-12">
                <label class="form-label fw-bold">Full name</label>
                <div class="text-uppercase">{{item.parent_first_name}} {{item.parent_middle_name}} {{item.parent_last_name}}</div>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-label fw-bold">Phone</label>
                <div>{{item.parent_mobile}}</div>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-label fw-bold">NIN</label>
                <div>{{item.parent_nin}}</div>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-label fw-bold">Gender</label>
                <div class="text-uppercase">{{item.parent_gender}}</div>
            </div>
            <div class="col-sm-6">
                <label class="form-label fw-bold">Occupation</label>
                <div>{{item.parent_occupation}}</div>
            </div>
            <div class="col-sm-6">
                <label class="form-label fw-bold">Religion</label>
                <div>{{item.parent_religion}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },
}
</script>

<style>

</style>