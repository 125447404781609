
<template>
<form autocomplete="off" class="patient-lookup-form">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row gy-3 align-items-end">
    <div class="col-lg-8">
      <div class="">
        <label class="form-label">Healthcare Number</label>
        <input type="text" v-model="data.number" class="form-control"
        name="number" placeholder="Enter Patient Number" :class="{ 'is-invalid': v$.data.number.$error }">     
      </div>
    </div>
    <div class="col-lg-4">
      <div class="d-flex">
        <button @click.prevent="popupModalQrCode = true" class="btn mb-0 me-2 btn-dark"  type="button">
          <i class="mdi mdi-camera-account"></i>
        </button>
        <button @click.prevent="lookup()" class="btn mb-0 flex-grow-1 btn-primary"  type="button">
          Search
        </button>
      </div>
    </div>
  </div>
  <b-modal v-model="popupModalQrCode" hide-footer centered
    title="Scan QR Code"
    title-class="fs-6" @hide="resetForm()">
    <qr-code-scanner v-if="popupModalQrCode"  @foundItem="foundItem($event)"/>
  </b-modal>
</form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import QrCodeScanner from '@/components/forms/QrCodeScanner.vue';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    IsLoading,
    QrCodeScanner
  },
  data() {
    return {
      isLoading: false,
      popupModalQrCode: false,
      data: {
        number: null,
      },
      mirrorData: null
    }
  },
  validations() {
    return {
      data:{
        number: {
          required: helpers.withMessage("BVN is required", required),
        },
      }
    }
  },
  methods: {
    lookup() {
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        this.isLoading = true
        this.$http.get(`/patients/lookup?${new URLSearchParams(this.data).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.foundItem(response.data.data)
          }else{
            this.$emit('notFound', null)
          }
        })
      })
    },
    foundItem(item){
      this.$emit('foundItem', item)
      this.resetForm();
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.popupModalQrCode = false
      this.v$.$reset()
      this.$emit('closeMe');
    },
  },
  mounted(){
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.$route.query.number){
      this.data.number = this.$route.query.number;
      this.lookup();
    }
  }
}
</script>

<style>
</style>