
<template>
<div>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Search for a Patient</h5>
      <div class="card loading-viewport">
        <div class="card-body">
          <patient-lookup-form @foundItem="showItem($event)" @notFound="showItem($event)" />
        </div>
      </div>
      <div v-if="patient" class="row mt-2 g-4">
        <div class="col-md-4 order-md-2">
          <div class="position-sticky" style="top: 90px;">
            <div class="card  bg-white overflow-hidden">
              <div class="card-body">
                <div class="">
                  <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                    <img :src="absoluteUrl(patient.image)"
                      class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                  </div>
                  <h5 class="fs-16 mb-1">
                    {{patient.first_name}} {{patient.last_name}}
                  </h5>
                  <p class="mb-0"> {{ patient.friendly_id }}</p>
                  <p class="mb-3"> Joined on {{$filters.date(patient.created_at)}}</p>

                  <button @click.prevent="popupModalShow = true" type="button" class="btn btn-success">Assign to a Doctor</button>
                </div>
              </div>
              <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
            </div>
            <div class="card bg-light">
              <img v-if="patient.id_card" class="w-100 card-img" :src="`${absoluteUrl(patient.id_card)}`" />
              <div class="card-body d-grid">
                <a :href="absoluteUrl(`/download?file_path=${patient.id_card}`)" class="btn mb-2 btn-sm btn-outline-primary" target="_blank">Download</a>
                <button @click.prevent="generateId()" 
                type="button" class="btn btn-sm btn-outline-dark">Re-Generate</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row g-3 g-md-4">
                <div class="col-12">
                  <label class="form-label fw-bold">Full name</label>
                  <div class="text-uppercase">{{patient.first_name}} {{patient.middle_name}} {{patient.last_name}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Number</label>
                  <div>{{patient.friendly_id}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Patient Category</label>
                  <div class="text-uppercase">{{patient.patient_category}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Email</label>
                  <div>{{patient.email}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Phone</label>
                  <div>{{patient.mobile}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">NIN</label>
                  <div>{{patient.nin}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Gender</label>
                  <div class="text-uppercase">{{patient.gender}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Date of Birth</label>
                  <div>{{$filters.date(patient.dob)}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Age</label>
                  <div class="">{{patient.age}} years</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Occupation</label>
                  <div>{{patient.occupation}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Religion</label>
                  <div>{{patient.religion}}</div>
                </div>
                <div class="col-12">
                    <label class="form-label fw-bold">Stret Address</label>
                    <div>{{patient.address}}</div>
                </div>
                <div class="col-12 col-md-6">
                    <label class="form-label fw-bold">Local Government</label>
                    <div v-if="patient.lga">{{ patient.lga.name}}</div>
                </div>
                <div class="col-6 col-md-6">
                    <label class="form-label fw-bold">State</label>
                    <div v-if="patient.state">{{patient.state.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="patient.kin">
              <patient-kin-card  :item="patient.kin"/>
          </div>
          <div v-if="patient.parent">
            <patient-parent-card :item="patient.parent" />
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-4">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="56" height="56" viewBox="0 0 256 256" xml:space="preserve">
        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
          <path d="M 31.464 62.912 c -8.057 0 -16.114 -3.066 -22.248 -9.2 C 3.273 47.77 0 39.868 0 31.464 S 3.273 15.158 9.216 9.216 C 15.158 3.273 23.06 0 31.464 0 S 47.77 3.273 53.712 9.216 c 12.268 12.267 12.268 32.228 0 44.496 C 47.578 59.846 39.521 62.912 31.464 62.912 z M 31.464 2 c -7.87 0 -15.269 3.065 -20.834 8.63 S 2 23.594 2 31.464 c 0 7.87 3.065 15.269 8.63 20.834 c 11.488 11.487 30.179 11.487 41.668 0 c 11.487 -11.488 11.487 -30.18 0 -41.668 C 46.732 5.065 39.333 2 31.464 2 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 58.668 59.668 c -0.256 0 -0.512 -0.098 -0.707 -0.293 l -5.663 -5.663 c -0.391 -0.391 -0.391 -1.023 0 -1.414 s 1.023 -0.391 1.414 0 l 5.663 5.663 c 0.391 0.391 0.391 1.023 0 1.414 C 59.18 59.57 58.924 59.668 58.668 59.668 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 84.983 90 c -1.34 0 -2.6 -0.521 -3.547 -1.47 L 57.961 65.056 c -1.956 -1.957 -1.955 -5.14 0 -7.095 c 0.947 -0.947 2.207 -1.469 3.548 -1.469 c 1.34 0 2.6 0.521 3.547 1.469 L 88.53 81.437 c 0.948 0.947 1.47 2.207 1.47 3.547 s -0.521 2.6 -1.47 3.547 l 0 0 l 0 0 l 0 0 C 87.583 89.479 86.323 90 84.983 90 z M 61.509 58.492 c -0.807 0 -1.564 0.313 -2.134 0.883 c -1.176 1.176 -1.176 3.09 0 4.267 l 23.476 23.475 c 1.141 1.141 3.125 1.141 4.266 0 l 0 0 c 0.57 -0.57 0.884 -1.327 0.884 -2.133 s -0.313 -1.563 -0.884 -2.133 L 63.642 59.375 C 63.072 58.806 62.314 58.492 61.509 58.492 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 38.575 25.142 c -0.106 0 -0.214 -0.017 -0.319 -0.052 c -0.427 -0.144 -0.706 -0.556 -0.679 -1.006 c 0.057 -0.97 0.026 -1.471 -0.021 -1.73 c -1.742 0.247 -3.791 0.327 -6.153 -0.576 c -1.83 -0.767 -4.377 -1.672 -5.697 -0.849 c -0.749 0.467 -1.18 1.568 -1.281 3.272 c -0.025 0.431 -0.325 0.797 -0.742 0.908 c -0.419 0.111 -0.859 -0.06 -1.094 -0.422 c -1.775 -2.732 -2.344 -5.413 -1.601 -7.548 c 0.508 -1.461 1.612 -2.583 3.128 -3.19 c 2.856 -4.208 8.601 -4.418 13.117 -2.721 c 0.378 0.142 0.633 0.497 0.648 0.9 c 0.014 0.403 -0.215 0.776 -0.582 0.944 c -0.203 0.093 -0.375 0.179 -0.521 0.258 c 2.32 1.165 3.77 2.642 4.317 4.403 c 0.627 2.02 0.059 4.364 -1.69 6.966 C 39.215 24.981 38.901 25.142 38.575 25.142 z M 37.89 22.303 c -0.005 0.001 -0.01 0.001 -0.014 0.002 C 37.881 22.305 37.886 22.304 37.89 22.303 z M 31.924 12.228 c -2.544 0 -4.94 0.875 -6.299 3.069 c -0.123 0.198 -0.31 0.347 -0.531 0.421 c -1.108 0.374 -1.875 1.091 -2.217 2.077 c -0.332 0.952 -0.269 2.104 0.166 3.366 c 0.371 -0.853 0.903 -1.492 1.605 -1.929 c 2.083 -1.301 5.101 -0.315 7.499 0.689 c 2.044 0.782 3.808 0.661 5.406 0.412 c 0.473 -0.086 0.948 0.037 1.312 0.357 c 0.083 0.073 0.156 0.153 0.222 0.239 c 0.302 -0.967 0.335 -1.837 0.098 -2.603 c -0.461 -1.485 -1.988 -2.749 -4.537 -3.758 c -0.462 -0.183 -0.72 -0.678 -0.604 -1.162 c 0.076 -0.316 0.236 -0.61 0.493 -0.892 C 33.671 12.329 32.789 12.228 31.924 12.228 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 30.992 37.857 c -0.56 0 -1.11 -0.097 -1.636 -0.287 c -3.707 -1.339 -6.314 -4.743 -6.643 -8.67 l -0.284 -4.698 c -0.034 -0.551 0.386 -1.025 0.938 -1.059 c 0.54 -0.042 1.025 0.386 1.059 0.938 l 0.283 4.675 c 0.262 3.128 2.354 5.859 5.328 6.934 c 0.614 0.222 1.296 0.223 1.911 0 c 2.975 -1.075 5.067 -3.805 5.33 -6.957 l 0.299 -4.656 c 0.036 -0.551 0.522 -0.963 1.062 -0.934 c 0.551 0.036 0.969 0.511 0.934 1.062 l -0.301 4.675 c -0.33 3.947 -2.938 7.351 -6.645 8.69 C 32.102 37.761 31.552 37.857 30.992 37.857 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 30.992 46.659 c -0.269 0.001 -0.527 -0.108 -0.715 -0.301 l -6.845 -7.002 c -0.386 -0.395 -0.379 -1.028 0.016 -1.415 c 0.396 -0.386 1.029 -0.377 1.414 0.016 l 6.13 6.271 l 6.129 -6.271 c 0.387 -0.394 1.02 -0.402 1.415 -0.016 c 0.395 0.386 0.402 1.02 0.016 1.415 l -6.845 7.002 C 31.519 46.551 31.261 46.659 30.992 46.659 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 16.864 53.477 c -0.552 0 -1 -0.447 -1 -1 v -9.344 c 0 -2.373 1.81 -4.4 4.303 -4.82 l 3.814 -0.642 c 0.993 -0.167 1.714 -0.938 1.714 -1.831 l 0.024 -1.012 c 0.014 -0.552 0.489 -0.994 1.024 -0.975 c 0.552 0.013 0.989 0.472 0.975 1.024 l -0.024 0.988 c 0 1.849 -1.422 3.448 -3.381 3.778 l -3.814 0.643 c -1.527 0.257 -2.635 1.455 -2.635 2.847 v 9.344 C 17.864 53.029 17.417 53.477 16.864 53.477 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 45.136 53.477 c -0.552 0 -1 -0.447 -1 -1 v -9.344 c 0 -1.393 -1.108 -2.59 -2.635 -2.847 l -3.814 -0.642 c -1.96 -0.331 -3.382 -1.93 -3.382 -3.803 l -0.023 -0.963 c -0.014 -0.552 0.423 -1.011 0.975 -1.024 c 0.553 -0.047 1.01 0.422 1.024 0.975 l 0.024 0.988 c 0 0.917 0.722 1.688 1.715 1.855 l 3.813 0.642 c 2.493 0.42 4.303 2.447 4.303 4.82 v 9.344 C 46.136 53.029 45.688 53.477 45.136 53.477 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        </g>
        </svg>
        <p>No record to show</p>
        <router-link to="/patients/create" class="btn btn-primary">Register New Patient</router-link>
      </div>
    </div>
  </div>
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Assign patient to a Doctor" body-class="loading-viewport"
    title-class="fs-6" @hide="initiallizeAll()">
      <hospital-patient-form v-if="popupModalShow" 
      :patientId="patient.id" @closeMe="initiallizeAll()" />
  </b-modal>
</div>

</template>
<script>
import PatientLookupForm from '@/components/forms/PatientLookupForm.vue'
import HospitalPatientForm from '@/components/forms/HospitalPatientForm.vue'
import PatientKinCard from '@/components/cards/PatientKinCard.vue'
import PatientParentCard from '@/components/cards/PatientParentCard.vue'

export default {
  components:{
    PatientLookupForm,
    HospitalPatientForm,
    PatientKinCard,
    PatientParentCard
  },
  data(){
    return{
      patient: null,
      popupModalShow: false
    }
  },
  methods: {
    showItem(item){
      this.patient = item
    },
    generateId(){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.get(`/patients/id-card?number=${this.patient.friendly_id}`)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue",false)
        if(response.data.success){
          Object.assign(this.patient, response.data.data)
        }
      })
    },
    initiallizeAll(){
      this.popupModalShow = false
    },
  },
}
</script>


<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>
