<template>
  <div>
    <div>
        <div v-if="fetchingData" style="border-radius:2rem;" class="card text-center loading-viewport">
            <is-loading v-if="fetchingData" :box="true" />
        </div>
        <div v-else>
            <qrcode-stream @decode="onDecode" />
        </div>
    </div>
  </div>
</template>
<script>
import { QrcodeStream } from "vue3-qrcode-reader";
import IsLoading from '@/components/IsLoading.vue';

export default {
    data(){
        return {
            fetchingData: false, 
            result: null
        }
    },
    components: {
        QrcodeStream,
        IsLoading,
    },
    methods: {
        onDecode(result) {
            this.fetchingData = true
            this.$http.get(`/patients/lookup?number=${result}`)
            .then((response) => {
                this.fetchingData = false
                if(response.data.success){
                    this.$emit('foundItem', response.data.data)
                }
            })
        },
    },
}
</script>

<style>

</style>